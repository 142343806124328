import React from "react";
import Markdown from "react-markdown"

import { graphql, Link } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import {getPageUrl} from '../lib/helpers'

import Layout from '../containers/layout'

import Breadcrumb from '../components/breadcrumb'
import BlogPostPreviewList from '../components/blog-post-preview-list'
import Container from '../components/container'
import RelatedPages from '../components/related-pages'
import SearchBar from '../components/search-bar'
import MetaSchemaSEO from '../components/seo';

import * as styles from './entity.module.css'




export const query = graphql`
  query AboutQuery($entityId: ID!) {
    strapi {
      entity( id: $entityId ) {
        id
        introduction
        large_main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                height: 400)
            }
          }
        }
        main_image {
          url
          caption
          alternativeText
          imageFile{
            childImageSharp{
              gatsbyImageData(
                height: 100)
            }
          }
        }
        contact_information {
            ... on STRAPI_ComponentContactDetailsTwitterHandle {
            id
            twitter_handle
            }
            ... on STRAPI_ComponentContactDetailsWebsiteUrl {
            id
            text_before_link
            link_text
            url
            }
            ... on STRAPI_ComponentContactDetailsInstructions {
            id
            instructions
            }
            ... on STRAPI_ComponentContactDetailsFacebookPageId {
            id
            }
        }
        name
        slug
        pages {
          id
          canonical_url
          slug
          page_type {
            name
            slug_prefix
          }
          site_section: page_type{
            name
            slug_prefix
          }
          title
          excerpt
          page_value
          updated_at
          parent {
            title
            title_short
            slug
            page_type {
              name
              slug_prefix
            }
          }
          main_image {
            url
            caption
            alternativeText
            imageFile{
              childImageSharp{
                gatsbyImageData(
                  quality: 70,
                  width: 350,
                  aspectRatio: 1,
                  transformOptions: {cropFocus:ENTROPY})
              }
            }
          }
        }
      }
      pages(sort: "created_at:desc", limit: 15, publicationState: LIVE, where: {authors:{id: $entityId}}) {
        id
        slug
        page_type {
          name
          slug_prefix
        }
        site_section: page_type {
          name
          slug_prefix
        }
        title
        published_at
        parent {
          id
        }
        authors {
          id
          name
          slug
        }
      }
    }
  }
`;

const AboutEntityPage = ( {data} ) => {
  var article = data.strapi.entity;
  article.title_short = article.name
  article.title = article.name
  article.parent = {"slug":'about', "title":'About'}
  const image = getImage(article?.main_image?.imageFile)
  const large_image = getImage(article?.large_main_image?.imageFile)
  const contributedPages = data.strapi.pages

  return (
    <Layout>
      <MetaSchemaSEO
        title = {`About `+article.name}
      />
        <Container>
          <Breadcrumb thisPage={article}></Breadcrumb>
        </Container>
        <div className={styles.headerCentered}>
          <h1 className={styles.title}>{article.name}</h1>
          {
            // display contact info
            article.contact_information && article.contact_information.length > 1 &&
              <>
                { article.contact_information.map(component => component && (
                  <>
                  { // display twitter
                  component.twitter_handle &&
                    <div>
                        Twitter <a href={`https://twitter.com/`+component.twitter_handle}>@{component.twitter_handle}</a>
                    </div>
                  }
                  { // display url
                  component.url &&
                    <div>
                      {component.text_before_link} <a href={component.url}>{component.link_text}</a>
                    </div>
                  }
                  </>
                ))}
              </>
          }
        </div>
        { article.main_image && !article.large_main_image &&
          <div className={styles.hero}>
            <GatsbyImage image={image} alt={article.main_image.alternativeText ? article.main_image.alternativeText : article.title } />
            <div className={styles.heroImageCopyright}>{article.main_image.caption}</div>
          </div>
        }
        { article.large_main_image &&
          <div className={styles.hero}>
            <GatsbyImage image={large_image} alt={article.large_main_image.alternativeText ? article.large_main_image.alternativeText : article.title } />
            <div className={styles.heroImageCopyright}>{article.large_main_image.caption}</div>
          </div>
        }
        <Container>
          { article.introduction && (
            <Markdown children={article.introduction} skipHtml={true} />
          )}
          {
          // add children posts if any
          article.pages && article.pages.length > 0 && (
              <BlogPostPreviewList
                  nodes={article.pages}
                  max_n="10"
                  page_type={article.page_type}
              />
          )}
          <SearchBar></SearchBar>
          {article.related_pages && article.related_pages.length > 0 && (
            <RelatedPages nodes={article.related_pages}></RelatedPages>
          )}
          {contributedPages && 
            <div className={styles.contributedPages}>
              <h3>Recently Contributed Content</h3>
              <ul className={styles.listPages}>
                  {contributedPages.map(post => (
                      <li key={post.id}>
                        <Link to={getPageUrl(post)}>{post.title}</Link>
                      </li>
                  ))}
              </ul>
            </div>
          }
        </Container>
    </Layout>
  );
};

export default AboutEntityPage;
